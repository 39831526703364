<template>
<v-main>
   <nav>
   <v-toolbar>
     <v-toolbar-title class="headline text-uppercase">
                      <v-app-bar-nav-icon  @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

     </v-toolbar-title>
                 <v-spacer></v-spacer>

            <img src="/img/logo.png" class="mx-4 px-4" style="width: 200px; height:40px;">

     <v-spacer></v-spacer>

             <v-btn class="mx-2" fab dark small color="black" href="/logout">
      <v-icon>mdi-logout</v-icon>
    </v-btn>


   </v-toolbar>

   <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list-item>

      <img class = "logo pr-4 text-align-center align-center"   src="/img/logo.png" style="width:150px;  height: 40px;">
        
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>

        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>
 </nav>

     

     <router-view/>

</v-main>
</template>

<script>

export default {


   data: function () {
    return {
      drawer: null,
      name: localStorage.getItem('name'),
      items: [
          { title: 'Dashboard', icon: 'mdi-google-analytics', link: '/'},
          { title: 'Sites', icon: 'mdi-receipt', link: '/sites/'},
          { title: 'Settings', icon: 'mdi-cog', link: '/settings/'},

        ],
    }
   },
   created: function (){


   },
   methods: {

   }
}
</script>

<style>
.bg-navbar{
background-color: #2a2a72;
background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);

}
.logo{
    width: 100%;
}
</style>
